<template>
  <div>
    <w-top-bar>Estadísticas de entregas</w-top-bar>

    <!-- Crear tabla con los valores -->
    <div class="h-full p-5 bg-gray-200">
      <table
        class="
          min-w-full
          overflow-hidden
          divide-y divide-gray-200
          rounded-lg
          border border-gray-500
        "
      >
        <thead class="hidden md:table-header-group print:table-header-group">
          <tr>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                leading-4
                tracking-wider
                text-left text-gray-500
                uppercase
                print:hidden
                md:table-cell
                bg-gray-50
              "
            >
              Estado
            </th>
            <th
              class="
                px-6
                py-3
                text-xs
                font-medium
                leading-4
                tracking-wider
                text-left text-gray-500
                uppercase
                print:hidden
                md:table-cell
                bg-gray-50
                text-center
              "
            >
              Cantidad
            </th>
            <th
              class="
                hidden
                px-6
                py-3
                text-xs
                font-medium
                leading-4
                tracking-wider
                text-left text-gray-500
                uppercase
                print:hidden
                md:table-cell
                bg-gray-50
              "
            ></th>
          </tr>
        </thead>
        <tbody
          class="
            flex-1
            bg-white
            divide-y divide-gray-300
            sm:flex-none
            print:flex-none
          "
        >
          <tr
            class="
              flex flex-col
              mb-2
              flex-no
              wrap
              sm:table-row
              print:table-row
              sm:mb-0
            "
            v-for="item in estadisticasDhl"
            :key="item._id"
          >
            <td class="px-6 py-3 overflow-hidden whitespace-no-wrap">
              {{ item._id }}
            </td>
            <td class="px-6 text-sm whitespace-no-wrap text-center">
              {{ item.total }}
            </td>
            <td
              class="hidden px-6 py-4 text-sm whitespace-no-wrap md:table-cell"
              style="vertical-align: top"
            ></td>
            <td
              style="vertical-align: top"
              class="
                px-6
                py-4
                text-sm
                font-medium
                leading-5
                whitespace-no-wrap
                flex
                justify-end
                items-end
              "
            >
              <!-- Else Editar -->
              <router-link
                class="text-indigo-600 hover:text-indigo-900"
                :to="{
                  name: 'shipments',
                  query: { dhl: item._id },
                }"
                >Ver Pedidos</router-link
              >
            </td>
          </tr>

          <!-- More rows... -->
        </tbody>
      </table>
    </div>
  </div>
</template>
  
  <script>
import { makeFindMixin } from "feathers-vuex";
export default {
  data() {
    return {
      busy: false,
      estadisticas: false,
    };
  },
  mixins: [makeFindMixin({ service: "estadisticas-dhl" })],
  computed: {
    estadisticasDhlParams() {
      return {
        query: {
          $sort: {
            name: 1,
          },
          $limit: 300,
        },
      };
    },
  },
  methods: {},
};
</script>
  
  <style lang="scss" scoped></style>
  